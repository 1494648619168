
const main_metadata = {
  ar:{
    title:'متجر قصر الاواني',
    desc:'قصر الأواني هو خيارك الأول إذا كنتي تبحثين عمن يوفر لك الجودة العالية والسعر المناسب، بمنتجات عديدة مختلفة تبدأ من أواني مطبخك الى زينة منزلك، مكان واحد يوفر لك جميع احتياجاتك ..'
  },
  en:{
    title:'QASR ALAWANI',
    desc:'Qasr Alawani is your first choice if you are looking for someone who provides you with high quality and the right price, with many different products that start from your kitchen utensils to your home decoration, one place that provides you with all your needs ..      '
  }
}
export const metadata = {
  home:{
    ar:{
      title:main_metadata.ar.title,
      desc: main_metadata.ar.desc
    },
    en:{
      title:main_metadata.en.title,
      desc: main_metadata.en.desc
    }
  },
  cart:{
    ar:{
      title:`عربة التسوق - ${main_metadata.ar.title}`,
      desc: main_metadata.ar.desc
    },
    en:{
      title:`Cart - ${main_metadata.en.title}`,
      desc: main_metadata.en.desc
    }
  },
  checkout:{
    ar:{
      title:` التسوق - ${main_metadata.ar.title}`,
      desc: main_metadata.ar.desc
    },
    en:{
      title:`Cart - ${main_metadata.en.title}`,
      desc: main_metadata.en.desc
    }
  },
  success:{
    ar:{
      title:` تأكيد الطلب - ${main_metadata.ar.title}`,
      desc: main_metadata.ar.desc
    },
    en:{
      title:`Success - ${main_metadata.en.title}`,
      desc: main_metadata.en.desc
    }
  },
  user:{
    ar:{
      title:`حسابي - ${main_metadata.ar.title}`,
      desc: main_metadata.ar.desc
    },
    en:{
      title:`My account - ${main_metadata.en.title}`,
      desc: main_metadata.en.desc
    }
  },
  addresses:{
    ar:{
      title:`العناوين - ${main_metadata.ar.title}`,
      desc: main_metadata.ar.desc
    },
    en:{
      title:`Addresses - ${main_metadata.en.title}`,
      desc: main_metadata.en.desc
    }
  },
  orders:{
    ar:{
      title:`طلباتي - ${main_metadata.ar.title}`,
      desc: main_metadata.ar.desc
    },
    en:{
      title:`My orders - ${main_metadata.en.title}`,
      desc: main_metadata.en.desc
    }
  },
  wishlist:{
    ar:{
      title:`قائمة امنياتي - ${main_metadata.ar.title}`,
      desc: main_metadata.ar.desc
    },
    en:{
      title:`My wishlist - ${main_metadata.en.title}`,
      desc: main_metadata.en.desc
    }
  },
}