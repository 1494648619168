import Link from 'next/link';
import Arrow from '../icons/Arrow';
import { useTranslation } from 'next-i18next';
import TimeCountDown from './TimeCountDown';
import { countDownHandler } from '@/utils/global';

const Title = ({
  title,
  isSwiper,
  counter,
  seeMoreUrl = null,
  language,
  swiper,
  titleUrl,
}) => {
  const { t } = useTranslation('home');

  console.log('counter/////', counter);

  return (
    <div className='flex items-center justify-between text-dark-gray sm:gap-7'>
      {title && (
        <>
          {!titleUrl && (
            <p
              className={`text-sm font-bold uppercase min-[360px]:text-sm sm:text-lg md:text-2xl`}
            >
              {title}
            </p>
          )}
          {titleUrl && (
            <Link
              href={titleUrl?.replace('//', '/')}
              className={`text-sm font-bold uppercase min-[360px]:text-sm sm:text-lg md:text-2xl`}
            >
              {title}
            </Link>
          )}
        </>
      )}
      <div className='flex items-center gap-3 sm:order-last sm:flex-1 sm:justify-end'>
        {counter && countDownHandler(counter?.['date-to'])?.isCountDown && (
          <TimeCountDown t={t} counter={counter} />
        )}
        {isSwiper && (
          <>
            <button onClick={() => swiper?.slideNext()} className='mt-[3px]'>
              <Arrow
                className={`mt-1 size-3 cursor-pointer ${
                  language.toLowerCase()?.includes('en') ? 'rotate-90' : '-rotate-90'
                } ${seeMoreUrl && 'max-sm:hidden'}`}
              />
            </button>
            <button onClick={() => swiper?.slidePrev()} className='mt-[3px]'>
              <Arrow
                className={`mt-1 size-3 cursor-pointer ${
                  language?.toLowerCase()?.includes('ar') ? 'rotate-90' : '-rotate-90'
                } ${seeMoreUrl && 'max-sm:hidden'}`}
              />
            </button>
          </>
        )}
      </div>
      {seeMoreUrl && (
        <Link href={seeMoreUrl} className='flex items-center gap-1 underline'>
          <span className='text-sm font-bold min-[360px]:text-base'>
            {t('See More')}
          </span>
          <Arrow
            className={`${
              language.toLowerCase()?.includes('en') ? '-rotate-90' : 'rotate-90'
            } mt-0.5 size-2.5`}
          />
        </Link>
      )}
    </div>
  );
};

export default Title;
