export function groupBy(arr, prop) {
  return arr?.reduce((acc, curr) => {
    acc[curr[prop]] = curr;
    return acc;
  }, {});
}

export function validateExpirationDate(dateFrom, dateTo) {
  const now = new Date();
  const from = new Date(dateFrom);
  const to = new Date(dateTo);
  return now > from && now < to;
}

export function parseJwt(token) {
  try {
    const tokenArr = token.split('.');
    const decodedPayload = JSON.parse(
      Buffer.from(tokenArr?.at(1), 'base64').toString('utf-8')
    );
    return decodedPayload;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
}

export function createOnceFunction(fn) {
  let called = false;
  return function (...args) {
    if (!called) {
      called = true;
      return fn(...args);
    } else {
      console.log('Function has already been called once.');
    }
  };
}

export const imgSourceHandler = (img) => {
  return img && img.startsWith('http')
    ? img
    : `${process.env.NEXT_PUBLIC_HOME_IMG_BASEURL}/${img}`;
};

export function compareObjects(obj1, obj2) {
  // Get the keys of the objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate over the keys and compare the values
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  // If all keys and values are equal, return true
  return true;
}

export function mergeArrays(arr1, arr2) {
  // Create a map to store unique objects by id
  const map = new Map();

  // Add objects from arr1 to the map
  arr1.forEach((obj) => map.set(obj.id, obj));

  // Add objects from arr2 to the map
  arr2.forEach((obj) => map.set(obj.id, obj));

  // Return an array of unique objects
  return Array.from(map.values());
}
