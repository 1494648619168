import { validatePrice } from '@/helpers/product';

export const productSchema = (product) => {
  const price = validatePrice(product?.prices_with_tax);

  return {
    '@id': '#product',
    '@context': 'https://schema.org ',
    '@type': 'Product',
    brand: {
      '@type': 'Brand',
      name: product?.option_text_brand?.at(0) || '',
    },
    sku: product?.sku,
    mpn: product?.sku,
    url:
      process.env.NEXT_PUBLIC_WEBSITE_URL + '/product/' + product?.rewrite_url,
    name: product?.name,
    image: product?._media_?.image?.at(0)?.image,
    description: product?.meta_description,
    offers: {
      '@type': 'Offer',
      priceCurrency: 'SAR',
      price: (price?.specialPrice || price?.originalPrice || 0)?.toFixed(2),
      priceValidUntil: product?.prices_with_tax?.discounted_price_to || '',
      itemCondition: 'https://schema.org/NewCondition ',
      availability: 'https://schema.org/InStock ',
    },
  };
};

export const homeSchema = () => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Corporation',
    name: 'qasralawani',
    alternateName: 'قصر الأواني',
    url: 'https://www.qasralawani.com/',
    logo: 'https://www.qasralawani.com/imgs/layout/Logo.png',
    sameAs: [
      'https://www.facebook.com/Qasrawani',
      'https://x.com/Qasralawani',
      'https://www.instagram.com/qasralawani',
      'https://www.youtube.com/@QasrAwani',
      'https://www.tiktok.com/@qasralawani',
      'https://www.snapchat.com/add/qasrawani',
    ],
  };
};
