import { countDownHandler } from '@/utils/global';
import { useEffect, useState } from 'react';

const TimeCountDown = ({ t, counter }) => {
  const [countDown, setCountDown] = useState(
    countDownHandler(counter?.['date-to'])
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountDown(countDownHandler(counter?.['date-to']));
    }, 1000);

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  return (
    countDown.isCountDown && (
      <span className='rounded-md text-xs max-sm:bg-[#DD2546] max-sm:px-2 max-sm:py-1 max-sm:text-white min-[360px]:text-sm sm:flex sm:gap-2 sm:text-base sm:font-extrabold'>
        {t('Ends in')} {/* <span className='mx-1 sm:hidden'>{t('at')}</span> */}
        <span className='gap-0.5 sm:flex'>
          {countDown.days}
          <span className='uppercase max-sm:hidden'>{t('Days')}</span>
        </span>
        <span>:</span>
        <span className='gap-0.5 sm:flex'>
          {countDown.hours}
          <span className='uppercase max-sm:hidden'>{t('Hours')}</span>
        </span>
        <span>:</span>
        <span className='gap-0.5 sm:flex'>
          {countDown.minutes}
          <span className='uppercase max-sm:hidden'>{t('Min')}</span>
        </span>
      </span>
    )
  );
};

export default TimeCountDown;
