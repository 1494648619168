import { useState, useEffect } from 'react';

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    if (typeof window != 'object') return;
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let screen = 'md';
  if (screenSize.width < 576) {
    screen = 'sm'; // Small devices (landscape phones, 576px and up)
  } else if (screenSize.width < 768) {
    screen = 'md'; // Medium devices (tablets, 768px and up)
  } else if (screenSize.width < 992) {
    screen = 'lg'; // Large devices (desktops, 992px and up)
  } else if (screenSize.width < 1200) {
    screen = 'xl'; // Extra large devices (large desktops, 1200px and up)
  } else {
    screen = '2xl'; // 2 Extra large devices (extra large desktops, 1400px and up)
  }
  return {
    screenSize: screen,
    isResponsive: typeof window != undefined ? screenSize.width < 576 : false,
    screenWidth: screenSize.width,
  };
};

export default useScreenSize;
