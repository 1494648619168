import { getAwsPersonalizeProducts } from '@/services/global/aws-personalize';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import store from 'store';
import useSWR from 'swr';
import { getCookie } from 'cookies-next';
import { getProductsBySkus } from '@/services/product';
// import { getMultiProductsRating } from '@/services/product/reviews';

// integrated directly with aws to get products accepts recommenderCode and extraPayload if needed
const useGetAwsPersonalizeProducts = (recommenderCode, extraPayload) => {
  const { data: session } = useSession();
  const { locale } = useRouter();
  const handshake = getCookie('handshake');
  const sessionId = store.get('sessionId') || '';
  const recommenderId = String(session?.user?.id || sessionId);

  // get products sku from aws
  const { data: productsSkuData } = useSWR(
    [
      'aws-personalize-products-sku',
      locale,
      recommenderId,
      recommenderCode,
      extraPayload,
    ],
    () =>
      getAwsPersonalizeProducts(recommenderId, recommenderCode, extraPayload),
    {
      revalidateOnFocus: false,
      revalidateOnMount: !!recommenderId,
      revalidateIfStale: true,
      dedupingInterval: 0,
    }
  );

  //  get products
  const productsSku =
    productsSkuData?.itemList?.map((prod) => prod?.itemId) || [];

  const { data: productsData } = useSWR(
    ['aws-personalize-products', locale, productsSku, recommenderCode],
    () =>
      productsSku?.length > 0 ? getProductsBySkus(handshake, productsSku) : {},
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateIfStale: true,
      dedupingInterval: 0,
    }
  );

  const products = productsData?.data?.data?.products || [];

  // // get products ratings
  // const productsEntityIds = products?.map((p) => p?.entity_id);

  // const { data: productsRatingsData } = useSWR(
  //   [
  //     'aws-personalize-products-ratings',
  //     locale,
  //     productsEntityIds,
  //     recommenderCode,
  //   ],
  //   () =>
  //     productsEntityIds?.length > 0
  //       ? getMultiProductsRating(productsEntityIds, handshake)
  //       : {},
  //   {
  //     revalidateOnFocus: false,
  //     revalidateOnMount: false,
  //     revalidateIfStale: true,
  //     dedupingInterval: 0,
  //   }
  // );

  return {
    products,
    // ratings: productsRatingsData
    ratings: null
  };
};

export default useGetAwsPersonalizeProducts;
